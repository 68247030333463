<template>
  <v-container grid-list-xl fluid>
    <listarCliente v-bind:titulo="'Mis Facturas'"></listarCliente>
    <consultaFE v-bind:codcli="cliente.cli_codigo"></consultaFE>
  </v-container>
</template>



<script>
    import clienteService from "@/services/ClienteServices";
    import listarCliente from "@/components/cliente/ListarClientes.vue";
    import consultaFE from "@/components/factura/consultaFE.vue";

    export default {
        name: "misFacturas",
        components: { consultaFE,listarCliente },
        data() {
            return {
            cliente: {},
            isAdmin: false
            };
        },
        created() {
            this.cliente = clienteService.current();
            this.$bus.$on('SelectorClienteSetCliente', () => {
            this.cliente = clienteService.current();
            });
        }
    };
</script>