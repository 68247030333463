<template>
  <section>
    <loading v-bind:dialog="isLoading"></loading>
    <v-layout row wrap>
      <v-flex lg12>
        <alert v-bind:tipo="notificacion.tipo" v-bind:mensaje="notificacion.mensaje"></alert>
        <v-spacer></v-spacer>
        <v-card v-show="!isLoading">
          <v-toolbar card color="white">
            <v-text-field
              flat
              solo
              disabled
              label="Listado de comprobantes fiscales"
              single-line
              hide-details
            ></v-text-field>
            <v-menu offset-y :nudge-left="170" :close-on-content-click="false">
              <v-btn icon slot="activator">
                <v-icon>more_vert</v-icon>
              </v-btn>
              <v-list>
                <v-list-tile
                  v-for="(item) in headers"
                  :key="item.value"
                  @click="changeSort(item.value)"
                >
                  <v-list-tile-title>
                    {{ item.text }}
                    <v-icon
                      v-if="pagination.sortBy === item.value"
                    >{{pagination.descending? 'arrow_downward':'arrow_upward'}}</v-icon>
                  </v-list-tile-title>
                </v-list-tile>
              </v-list>
            </v-menu>
          </v-toolbar>
          <v-divider></v-divider>
          <v-card-text>
            <v-layout v-resize="onResize" column>
              <v-data-table
                :headers="headers"
                :items="comprobantes"
                :pagination.sync="pagination"
                :hide-headers="isMobile"
                :class="{mobile: isMobile}"
                :rows-per-page-items="rowsperpageitems"
              >
                <template slot="items" slot-scope="props">
                  <tr v-if="!isMobile">
                    <td>{{ props.item.fv0_fecemi | fecha }}</td>
                    <td>{{ props.item.fv0_tipcmp }}</td>
                    <td>{{ props.item.fv0_tipfor }}</td>
                    <td>{{ props.item.fv0_sucurs }}</td>
                    <td>{{ props.item.fv0_numero }}</td>
                    <td>{{ props.item.fv0_imptot | moneda }}</td>
                    <td>{{ props.item.saldo | moneda }}</td>
                    <td>
                      <v-btn @click="facturaElectronicaPDF(props.item.fv0_codigo)"  color="primary" fab small dark title="Imprimir comprobante">
                        <v-icon>local_printshop</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                  <tr v-else>
                    <td>
                      <ul class="flex-content">
                        <li class="flex-item" data-label="Fecha">{{ props.item.fv0_fecemi | fecha }}</li>
                        <li class="flex-item" data-label="Comprobante">{{ props.item.fv0_tipcmp }}</li>
                        <li class="flex-item" data-label="Tipo">{{ props.item.fv0_tipfor }}</li>
                        <li class="flex-item" data-label="Sucursal">{{ props.item.fv0_sucurs }}</li>
                        <li class="flex-item" data-label="Nro">{{ props.item.fv0_numero }}</li>
                        <li class="flex-item" data-label="Obervaciones">{{ props.item.fv0_observ }}</li>
                        <li class="flex-item" data-label="Importe ($)">{{ props.item.fv0_imptot | moneda }}</li>
                        <li class="flex-item" data-label="Saldo del Comp.">{{ props.item.saldo | moneda }}</li>
                        <li class="flex-item" data-label="Acciones">
                          <!-- <v-btn color="primary" fab small dark title="Imprimir comprobante">
                            <v-icon>local_printshop</v-icon>
                          </v-btn> -->
                        </li>
                        <li class="enter"></li>
                      </ul>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </section>
</template>

<script>
import DataTableMixin from "@/mixins/DataTableMixin";
import utils from '@/plugins/Utils.js';
import alert from "@/components/alert/alert.vue";
import loading from "@/components/share/Loading.vue";
import FacturaVentaService from "@/services/FacturaVentaServices"
import FacturaVentaItemService from "@/services/FacturaVentaItemServices"
import EmpresaService from "@/services/EmpresaServices"
import ClienteService from "@/services/ClienteServices"

import reporteFactura from "@/reports/factura/reporteFactura";

export default {
  name: 'ConsultarFE',
  mixins: [DataTableMixin,utils.mixins],
  components: { alert,loading },
  props: {
    codcli: {
      type: Number,
      required: true
    }
  },
  computed: {},
  data() {
    return {
      Total: 0,
      comprobantes: [],
      isLoading: false,
      notificacion: { mensaje: "", tipo: "error" },
      pagination: { sortBy: "fv0_fecemi" },
      isMobile: false,
      headers: [
        { text: "Fecha", align: "left", value: "fv0_fecemi" },
        { text: "Comprobante", value: "fv0_tipcmp" },
        { text: "Tipo", value: "fv0_tipfor" },
        { text: "Sucursal", value: "fv0_sucurs" },
        { text: "Nro", value: "fv0_numero" },
        { text: "Importe ($)", value: "fv0_imptot" },
        { text: "Saldo del Comp. ($)", value: "saldo" },
        { text: "Acciones", value: "-" }
      ]
    };
  },
  created() {
    this.Cargar();
  },
  methods: {
    Cargar() {
      //Verificamos que el codigo de cliente se mayor a 0
      if (this.codcli < 1) {
        return false;
      }
      this.isLoading = true;
      FacturaVentaService
        .consultarElectronicasCliente(this.codcli)
        .then(res => {
          this.notificacion.mensaje = "";
          //Limpiamos el array comprobantes
          this.comprobantes = [];
          this.Total = 0;
          //Cargamos solo los elementos que cumplan
          //con las condiciones de filtrado
          this.comprobantes = res.filter((Element) => {
            this.Total += Number.parseFloat(Element.fv0_imptot)? Number.parseFloat(Element.fv0_imptot) : 0;

            return Element;
          });
        })
        .catch(err => {
          this.notificacion.mensaje =
            "Ocurrio un error al intentar recuperar los registros.";
          if (err.response && err.response.data.hasOwnProperty("errorMessage")) {
            this.notificacion.mensaje = err.response.data.errorMessage;
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    Buscar() {
      //Verificamos que se cumplan las validaciones
      if (!this.$refs.form.validate()) {
        return false;
      }
      this.Cargar();
    },
    facturaElectronicaPDF(codigo) {
      //llamar a servicio factura
      var datosFactura = {};
      
      this.isLoading = true;

      Promise.all([
        FacturaVentaService.consultarElectronica(codigo), 
        FacturaVentaService.consultarQr(codigo),
        FacturaVentaItemService.consultar(codigo),
        EmpresaService.consultar(),
        ClienteService.getbyID(this.codcli),
        FacturaVentaService.consultarCuadroTotales(codigo),
        FacturaVentaService.consultarOtrosTributos(codigo)
      ]).then(results => {
        // Use the data from the results like so:
        datosFactura.fv0 = results[0];
        datosFactura.QR  = results[1];
        datosFactura.fv1 = results[2];
        datosFactura.emp = results[3];
        datosFactura.cli = results[4];
        datosFactura.totales = results[5];
        datosFactura.tributos = results[6];
        
        reporteFactura.generarPDF(datosFactura);
      })
      .catch(err => {
        this.notificacion.mensaje =
          "Ocurrió un error al intentar generar el documento PDF.";
        if (err.response && err.response.data.hasOwnProperty("errorMessage")) {
          this.notificacion.mensaje = err.response.data.errorMessage;
        }
      })
      .finally(() => {
        this.isLoading = false;
      });
    }
  }
};
</script>